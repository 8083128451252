<template>
<div>
  <b-alert variant="warning" :show="useEmulators">
    Using emulator environment.
  </b-alert>  
  <router-view></router-view>
</div>
</template>

<script>
import { useEmulators } from "@/services/firebase";

export default {
  data() {
    return {
      useEmulators
    };
  },
}
</script>

<style>

</style>